const carriers = {
  PR: ['Open Mobile', 'Claro Puerto Rico'],
  PS: ['Jawwal', 'Wataniya'],
  PT: ['Vodafone', 'MEO', 'NOS'],
  PW: ['PNCC'],
  PY: ['Copaco', 'Claro/Hutchison', 'Tigo', 'Personal', 'VOX'],
  QA: ['Vodafone', 'ooredoo', 'Ministry of Interior'],
  AE: ['Etisalat', 'du', 'Thuraya', 'Iridium'],
  AF: ['AWCC', 'MTN', 'WASEL', 'Etisalat', 'Salaam'],
  AG: ['Digicel', 'APUA', 'LIME'],
  AI: ['LIME'],
  AL: ['Telekom.al', 'Eagle Mobile', 'Vodafone'],
  AM: ['Orange', 'Beeline'],
  AO: ['MOVICEL', 'UNITEL'],
  AR: ['Nextel', 'Personal', 'Movistar', 'Claro'],
  AT: ['3AT', 'A1 TA', 'upc', 'Tele2', 'Vectone Mobile'],
  RE: ['Orange'],
  AU: ['Optus', 'Telstra', 'iiNet', 'Railcorp', 'Vodafone', 'Lycamobile', 'AAPT'],
  AW: ['SETAR'],
  AZ: ['Bakcell', 'Azercell', 'Azerfon'],
  RO: ['Digi.Mobil', 'Vodafone', 'Orange', 'Idilis', 'Telekom'],
  BA: ['BH Mobile', 'm:tel'],
  BB: ['Digicel', 'LIME'],
  RS: ['Orion', 'VIP', 'mt:s', 'Telenor'],
  BD: ['Grameenphone', 'Banglalink', 'Robi', 'Airtel', 'TeleTalk'],
  RU: [
    'Beeline',
    'MTS',
    'TTK',
    'MegaFon',
    'Tele2',
    'INDIGO',
    'NCC',
    'Vainakh',
    'Utel',
    'Tambov GSM',
    'NTC',
    'ETK',
    'Yota',
    'Krymtelekom',
  ],
  BE: ['BASE', 'Proximus', 'Mobistar', 'Voxbone', 'Transatel', 'JOIN'],
  BF: ['Telecel Faso', 'Telmob', 'Airtel'],
  RW: ['MTN', 'Airtel'],
  BG: ['Vivacom', 'Telenor', 'MAX'],
  BH: ['VIVA Bahrain', 'zain BH', 'Batelco'],
  BI: ['Leo', 'Smart Mobile', 'Spacetel', 'Onatel'],
  BJ: ['BBCOM', 'MTN', 'Moov', 'Glo', 'Libercom'],
  BM: ['CellOne'],
  BN: ['DSTCom'],
  BO: ['Nuevatel', 'Entel', 'Tigo'],
  SA: ['Al Jawal (STC)', 'Mobily', 'Zain SA'],
  SB: ['BeMobile', 'BREEZE'],
  BQ: ['Telbo'],
  BR: [
    'TIM',
    'Oi',
    'Conecta',
    'Vivo',
    'CTBC Celular',
    'Brasil Telecom GSM',
    'datora',
    'Claro BR',
    'Nextel',
  ],
  SC: ['Airtel', 'Cable \u0026 Wireless'],
  SD: ['Zain SD', 'Sudani One', 'canar'],
  BS: ['BaTelCo'],
  SE: [
    '3',
    'Telia',
    'Tele2',
    'Net 1',
    'MobiSir',
    'Telenor',
    'SWEDEN',
    'Maingate',
    'Sweden 3G',
    'CLX',
  ],
  BT: ['TashiCell'],
  SG: ['SingTel', 'StarHub', 'M1', 'Grid', 'Alltel', 'Thuraya'],
  BW: ['Orange', 'Mascom', 'BTC Mobile'],
  SI: ['A1', 'Mobitel'],
  BY: ['life:)', 'velcom', 'MTS'],
  SK: ['Orange', 'O2', 'Swan', 'Telekom'],
  BZ: ['DigiCell', 'SMART'],
  SL: ['Airtel', 'Africell', 'Smart Mobile'],
  SM: ['PRIMA'],
  SN: ['Orange', 'Tigo', 'Expresso'],
  SO: ['Hormuud', 'Somtel', 'Golis', 'Telesom'],
  CA: [
    'Telus',
    'Videotron',
    'Execulink',
    'Rogers Wireless',
    'ICE Wireless',
    'Wightman Mobility',
    'Globalstar',
    'Airtel Wireless',
    'Keewaytinook Mobile',
    'DMTS',
    'Quadro Mobility',
    'Bell',
    'SaskTel',
    'NetSet',
    'Wind Mobile',
    'Fido',
    'TBay',
    'MTS',
    'EastLink',
  ],
  SR: ['Digicel', 'Telesur'],
  SS: ['Zain', 'MTN'],
  CD: ['Airtel', 'Africell', 'Vodacom', 'Tigo'],
  ST: ['CSTmovel'],
  SV: ['movistar', 'CTE Telecom Personal', 'Tigo', 'digicel'],
  CF: ['Orange', 'TC'],
  CG: ['Warid Telecom', 'Airtel'],
  CH: [
    'Sunrise',
    'Swisscom',
    'Cablecom',
    'OnAir',
    'Telecom26',
    'Salt',
    'Lycamobile',
    'Transatel',
    'beeone',
  ],
  SX: ['Telcell'],
  SY: ['MTN', 'Syriatel'],
  CI: ['Orange', 'GreenN', 'Moov', 'MTN'],
  SZ: ['Swazi MTN'],
  CK: ['Kokanet'],
  CL: [
    'Nextel',
    'Netline',
    'VTR MOvil',
    'movistar',
    'entel',
    'Claro',
    'Will',
    'TelefOnica del Sur',
  ],
  CM: ['Orange', 'Nexttel', 'MTN Cameroon'],
  CN: ['China Telecom', 'China Unicom', 'China Mobile', 'China Tietong'],
  CO: [
    'Edatel',
    'movistar',
    'Tigo',
    'AVANTEL',
    'Claro',
    'eTb',
    'EMCALI',
    'DirecTV',
    'Virgin Mobile',
  ],
  TC: ['C\u0026W'],
  CR: ['movistar', 'Kolbi ICE', 'Claro'],
  TD: ['Salam', 'Airtel'],
  CU: ['CUBACEL'],
  CV: ['T+', 'CVMOVEL'],
  TG: ['Togo Cell'],
  TH: ['TOT 3G', 'dtac', 'TrueMove H', 'AIS', 'my by CAT', 'WE PCT', 'AIS GSM 1800'],
  CW: ['Digicel', 'Chippie'],
  CY: ['MTN', 'PrimeTel'],
  TJ: ['Beeline', 'Megafon Tajikistan', 'Tcell'],
  CZ: ['Vodafone', 'O2', 'ASTELNET'],
  TL: ['Telemor', 'Telkomcel', 'TT'],
  TM: ['MTS'],
  TN: ['Tunicell', 'OOREDOO TN', 'Orange'],
  TO: ['Digicel'],
  TR: ['Turkcell', 'Turk Telekom', 'Vodafone'],
  TT: ['Digicel', 'bmobile'],
  DE: [
    'Telekom',
    'Vodafone',
    'O2',
    'Debitel',
    'NetCologne',
    'DB Netz AG',
    'simquadrat',
    'MTX',
    'EMnify',
    'Dolphin Telecom',
    'Airdata',
  ],
  TW: ['Chunghwa LDM', 'VIBO', 'FarEasTone', 'VMAX', 'Taiwan Mobile', 'APTG', 'Ambit', 'TransAsia'],
  TZ: [
    'Zantel',
    'Airtel',
    'Vodacom',
    'Benson Online (BOL)',
    'tiGO',
    'SmileCom',
    'Hits',
    'TTCL Mobile',
  ],
  DJ: ['Evatis'],
  DK: [
    'TDC',
    'Telenor',
    'Telia',
    '3',
    'TetraNet',
    'Interactive digital media GmbH',
    'Vectone Mobile',
    'Lycamobile',
  ],
  DM: ['LIME'],
  DO: ['Viva', 'Orange', 'Claro', 'Wind', 'Tricom'],
  UA: ['MTS', 'Kyivstar', 'IT', '3Mob', 'lifecell', 'PEOPLEnet', 'MOTIV'],
  UG: [
    'Smile',
    'Orange',
    'MTN',
    'Airtel',
    'Warid Telecom',
    'Suretelecom',
    'Vodafone',
    'Uganda Telecom',
  ],
  DZ: ['Mobilis', 'Ooredoo', 'Djezzy'],
  US: [
    'AT\u0026T',
    'Verizon',
    'Cricket Communications',
    'Indigo Wireless',
    'Choice Wireless',
    'ClearTalk',
    'Alltel',
    'Mobile Nation',
    'Iridium',
    'metroPCS',
    'Aeris',
    'Sprint',
    'Illinois Valley Cellular',
    'Convey',
    'Bluegrass Wireless',
    'Boost',
    'GCI',
    'Cablevision',
    'Sprint Spectrum',
    'U.S. Cellular',
    'Numerex',
    'Nemont',
    'Clear',
    'Epic Touch',
    'C Spire Wireless',
    'VeriSign',
    'Sprocket',
    'Arinc',
    'Unicel',
    'First Cellular',
    'XIT Wireless',
    'Globalstar',
    'Union Wireless',
    'Cellcom',
    'Carolina West Wireless',
    'Virgin Mobile',
    'West Central',
    'Coleman County Telecom',
    'Cellular One',
    'Gci Communications Corp.',
    'Bug Tussel Wireless',
    'MINTA',
    'Big River Broadband',
    'Plateau Wireless',
    'Advantage',
    'Long Lines Wireless',
    'Globecomm',
    'Jasper',
    'Corr',
    'iConnect',
    'United Wireless',
    'Triangle Communications',
    'Adams Networks',
    'PinPoint',
    'Immix',
    'Nextel',
    'Panhandle',
    'Westlink',
    'PACE',
    'Sprint Corporation',
    'Big Sky Mobile',
    'CTCI',
    'Chariton Valley',
    'Pioneer Cellular',
    'i wireless',
    'Consolidated Telcom',
    'Emery Telcom Wireless',
    'Kineto Wireless',
    'VTel Wireless',
    'Redzone Wireless',
    'Northeast Wireless',
    'Farmers Cellular',
    'Cellular One of East Texas',
    'Sprocket Wireless',
    'Northwest Missouri Cellular',
    'i CAN_GSM',
    'nTelos',
    'Pine Cellular',
    'Airadigm',
    'Cincinnati Bell',
    'Docomo',
    'INET',
    'Appalachian Wireless',
    'Stelera Wireless',
    'Five Star Wireless',
    'AmeriLink PCS',
    'PetroCom',
    'Airlink PCS',
    'ETC',
    'Transatel',
    'Cell One Amarillo',
    'Viaero',
    'Lycamobile',
    'NewCore',
    'MTA',
    'West Virginia Wireless',
    'Fisher Wireless Services Inc',
    'Airtel',
    'Arctic Slope',
    'Jasper Systems',
    'Caprock',
    'telna Mobile',
    'Rock Wireless',
  ],
  EC: ['Claro', 'CNT Mobile', 'Movistar'],
  EE: ['Telia', 'Elisa', 'Tele2'],
  EG: ['Vodafone', 'Orange', 'Etisalat'],
  UY: ['Claro', 'Movistar', 'Ancel'],
  UZ: ['Beeline', 'UzMobile', 'UMS', 'Ucell'],
  VC: ['Lime', 'Digicel'],
  ER: ['Eritel'],
  ES: [
    'movistar',
    'ONO',
    'MObil R',
    'Vodafone',
    'Yoigo',
    'Orange',
    'Euskaltel',
    'Eurona',
    'TeleCable',
    'Simyo',
    'BT',
    'Murcia4G',
    'Jazztel',
    'DigiMobil',
    'Tuenti',
    'Aire',
    'ADIF',
  ],
  VE: ['movistar', 'Movilnet', 'Digitel'],
  VG: ['Digicel', 'LIME', 'CCT Boatphone'],
  VN: ['Vinaphone', 'Viettel Mobile', 'MobiFone', 'Vietnamobile', 'EVNTelecom'],
  VU: ['WanTok', 'SMILE'],
  FI: ['Elisa', 'DNA', 'Sonera', 'Ukko Mobile', 'Liikennevirasto'],
  FJ: ['Vodafone', 'Digicel'],
  FK: ['sure'],
  FM: ['FSMTC'],
  FO: ['Faroese Telecom'],
  FR: [
    'Orange',
    'Bouygues',
    'Free Mobile',
    'SFR',
    'MobiquiThings',
    'Completel Mobile',
    'RFF',
    'Only',
    'Voxbone',
    'Transatel',
    'Sisteer',
  ],
  GA: ['Moov', 'Libertis', 'Airtel'],
  GB: [
    'Talk Talk',
    'BT',
    'Vodafone UK',
    'Vectone Mobile',
    'O2',
    'UK Broadband',
    'Virgin Mobile UK',
    'Lycamobile',
    'Iridium',
    'Truphone',
    '3',
    'Private Mobile Networks PMN',
    'Inmarsat',
    'Transatel',
  ],
  WS: ['Digicel', 'Bluesky'],
  GD: ['Cable \u0026 Wireless'],
  GE: ['Silknet', 'MagtiCom', 'Geocell', 'Beeline'],
  GF: ['Orange'],
  GG: ['Sure Mobile'],
  GH: ['Vodafone', 'Expresso', 'MTN', 'Airtel', 'Surfline', 'tiGO', 'Blu', 'Globacom'],
  GI: ['GibTel', 'Shine'],
  GL: ['TELE'],
  GM: ['QCell', 'Africel', 'Comium', 'Gamcel'],
  GN: ['Cellcom', 'MTN', 'Telecel Guinee', 'Orange S.A.', 'Sotelgui'],
  GP: ['Orange', 'Digicel'],
  GQ: ['Orange GQ'],
  GR: ['OTE', 'Vodafone', 'Cosmote', 'Wind', 'Cyta Hellas', 'Cosmoline'],
  GT: ['Claro', 'movistar', 'Tigo'],
  GU: ['GTA Wireless', 'IT\u0026E Wireless'],
  GW: ['Orange'],
  GY: ['GT\u0026T Cellink Plus'],
  HK: [
    'PCCW Mobile (2G)',
    '1O1O / One2Free / New World Mobility',
    'Truphone',
    'CMCC HK',
    'China Unicom',
    'SmarTone',
    '3 (2G)',
    'Lycamobile',
    'CITIC Telecom',
    'China Mobile',
    '1O1O / One2Free / New World Mobility / SUNMobile',
  ],
  HN: ['Hondutel', 'Tigo', 'Digicel', 'Claro'],
  HR: ['Vip'],
  YE: ['SabaFon', 'MTN', 'Yemen Mobile'],
  HT: ['Natcom', 'Voila', 'Digicel'],
  HU: ['Vodafone', 'upc', 'Telenor'],
  ID: [
    'XL',
    'Telkomsel',
    '3',
    'Esia',
    'PSN',
    'SMARTFREN',
    'Indosat Ooredoo',
    'Ceria',
    'BOLT! Super 4G',
    'Fren/Hepi',
  ],
  YT: ['Orange'],
  IE: ['Virgin Mobile', '3', 'Vodafone', 'eMobile', 'Tesco Mobile', 'Meteor', 'Carphone Warehouse'],
  IL: [
    'Orange',
    'Cellact Communications',
    'Pelephone',
    'Golan Telecom',
    'Cellcom',
    '012 Telecom',
    'Hot Mobile',
    'Youphone',
  ],
  IM: ['Pronto GSM', 'Cloud9'],
  IN: [
    'Vodafone IN',
    'IDEA',
    'TATA DOCOMO',
    'AirTel',
    'Uninor',
    'CellOne',
    'Aircel',
    'Jio',
    'DOLPHIN',
    'IT\u0026E Wireless',
    'Reliance',
  ],
  ZA: [
    'Vodacom',
    'Cell C',
    'iBurst',
    'MTN',
    'Telkom Mobile / 8.ta / Telekom SA',
    'Neotel',
    'Sentech',
  ],
  IQ: ['Asia Cell', 'Itisaluna', 'Zain', 'Omnnea', 'Korek'],
  IR: ['Irancell', 'Rightel', 'MTCE'],
  IS: ['SIminn', 'Nova', 'Vodafone', 'Viking'],
  IT: ['Vodafone', 'TIM', 'Wind', 'RFI', '3 Italia', 'Poste Mobile'],
  ZM: ['Airtel', 'ZAMTEL', 'MTN'],
  JE: ['Sure Mobile', 'Marathon'],
  ZW: ['Econet', 'Telecel', 'Net*One'],
  JM: ['Digicel', 'LIME'],
  JO: ['Umniah', 'zain JO', 'Orange'],
  JP: [
    'SoftBank',
    'freetel',
    'NTT DoCoMo',
    'KDDI',
    'Wireless City Planning',
    'Y!Mobile',
    'UQ WiMAX',
    'au',
  ],
  KE: ['Safaricom', 'Airtel', 'Orange Kenya'],
  KG: ['MegaCom', 'Beeline', 'O!'],
  KH: ['Smart', 'Cellcard', 'Beeline', 'SEATEL', 'qb'],
  KM: ['Comoros Telecom'],
  KN: ['LIME'],
  KR: ['olleh', 'LG U+', 'SKTelecom', 'tplus'],
  KW: ['zain KW', 'Viva', 'Wataniya'],
  KY: ['Digicel', 'LIME'],
  KZ: ['Kcell', 'Altel', 'Beeline', 'Tele2.kz'],
  LA: ['LaoTel', 'ETL', 'Unitel', 'Beeline'],
  LB: ['Ogero Mobile', 'Alfa', 'touch'],
  LC: ['Cable \u0026 Wireless', 'Digicel'],
  LI: ['FL1'],
  LK: ['Airtel', 'Mobitel', 'Etisalat', 'Hutch', 'Dialog', 'SLT', 'Lanka Bell'],
  LR: ['LIBTELCO', 'Cellcom', 'Lonestar Cell'],
  LS: ['Vodacom', 'Econet Telecom'],
  LT: ['Tele2', 'Top Connect', 'MEZON', 'BITE', 'Mediafon', 'LitRail', 'Omnitel'],
  LU: ['Tango', 'POST', 'MTX'],
  LV: ['IZZI', 'LMT', 'TRIATEL', 'Bite'],
  LY: ['Libyana', 'Libya Phone', 'Madar'],
  MA: ['MEditel', 'INWI', 'Wana Corporate', 'IAM'],
  MC: ['Z Mobile', 'OnAir'],
  MD: ['UnitE', 'IDC', 'Moldcell', 'Orange'],
  ME: ['m:tel CG'],
  MF: ['Dauphin', 'Orange'],
  MG: ['Orange', 'Airtel', 'Telma'],
  MH: ['MINTA'],
  MK: ['Vip MK'],
  ML: ['Orange', 'Malitel'],
  MM: ['Telenor Myanmar', 'MPT', 'Ooredoo'],
  MN: ['Unitel', 'MobiCom'],
  MO: ['CTM', 'SmarTone'],
  MQ: ['Orange', 'Digicel'],
  MR: ['Chinguitel', 'Mauritel', 'Mattel'],
  MS: ['Cable \u0026 Wireless'],
  MT: ['Vodafone', 'Melita', 'GO'],
  MU: ['Orange', 'MTML', 'Emtel'],
  MV: ['Dhiraagu', 'Ooredoo'],
  MW: ['Airtel', 'TNM'],
  MX: ['AT\u0026T', 'movistar', 'Telcel', 'Unefon'],
  MY: ['Celcom', 'Yes', 'Maxis', 'U Mobile', 'P1', 'Altel', 'DiGi'],
  MZ: ['Vodacom', 'mCel', 'Movitel'],
  NA: ['MTC', 'switch', 'Paratus'],
  NE: ['Moov', 'SahelCom', 'Orange', 'Airtel'],
  NF: ['Norfolk Telecom'],
  NG: ['Visafone', 'Spectranet', 'Etisalat', 'Glo', 'MTN', 'Airtel', 'Ntel', 'Smile'],
  NI: ['movistar', 'Claro'],
  NL: [
    'Ziggo',
    'Vodafone',
    'KPN',
    'Lycamobile',
    'ProRail',
    'Tele2',
    'Teleena',
    'Intercity Zakelijk',
    'Voiceworks',
    'Vectone Mobile',
    'ASPIDER Solutions Nederland B.V.',
  ],
  NO: ['Telenor', 'Com4', 'Telia', 'TDC', 'Phonero', 'Tele2', 'Ice', 'Jernbaneverket'],
  NP: ['Ncell'],
  NR: ['Digicel'],
  NZ: ['Vodafone', 'XT Mobile Network', '2degrees'],
  OM: ['Nawras', 'Oman Mobile'],
  PA: ['Digicel', 'movistar', 'Claro', 'Cable \u0026 Wireless'],
  PE: ['Viettel Mobile', 'NEXTEL', 'Movistar', 'Claro (TIM)'],
  PF: ['VITI'],
  PG: ['citifon', 'Digicel'],
  PH: ['Globe', 'Smart', 'Sun'],
  PK: ['Telenor', 'Ufone', 'Zong', 'Warid Pakistan', 'Mobilink', 'PTCL'],
  PL: [
    'Plus',
    'Cyfrowy Polsat',
    'Play',
    'Orange',
    'Netia',
    'Nordisk Polska',
    'Aero2',
    'CenterNet',
    'Truphone',
    'Lycamobile',
    'Sferia',
    'PKP',
  ],
  PM: ['Ameris'],
};

const countryOptions = [
  {
    label: 'Afghanistan',
    value: 'af',
  },
  {
    label: 'Åland Islands',
    value: 'ax',
  },
  {
    label: 'Albania',
    value: 'al',
  },
  {
    label: 'Algeria',
    value: 'dz',
  },
  {
    label: 'Andorra',
    value: 'ad',
  },
  {
    label: 'Angola',
    value: 'ao',
  },
  {
    label: 'Anguilla',
    value: 'ai',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'ag',
  },
  {
    label: 'Argentina',
    value: 'ar',
  },
  {
    label: 'Armenia',
    value: 'am',
  },
  {
    label: 'Aruba',
    value: 'aw',
  },
  {
    label: 'Australia',
    value: 'au',
  },
  {
    label: 'Austria',
    value: 'at',
  },
  {
    label: 'Azerbaijan',
    value: 'az',
  },
  {
    label: 'Bahamas',
    value: 'bs',
  },
  {
    label: 'Bahrain',
    value: 'bh',
  },
  {
    label: 'Bangladesh',
    value: 'bd',
  },
  {
    label: 'Barbados',
    value: 'bb',
  },
  {
    label: 'Belarus',
    value: 'by',
  },
  {
    label: 'Belgium',
    value: 'be',
  },
  {
    label: 'Belize',
    value: 'bz',
  },
  {
    label: 'Benin',
    value: 'bj',
  },
  {
    label: 'Bermuda',
    value: 'bm',
  },
  {
    label: 'Bhutan',
    value: 'bt',
  },
  {
    label: 'Bolivia',
    value: 'bo',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'bq',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'ba',
  },
  {
    label: 'Botswana',
    value: 'bw',
  },
  {
    label: 'Brazil',
    value: 'br',
  },
  {
    label: 'Brunei Darussalam',
    value: 'bn',
  },
  {
    label: 'Bulgaria',
    value: 'bg',
  },
  {
    label: 'Burkina Faso',
    value: 'bf',
  },
  {
    label: 'Cambodia',
    value: 'kh',
  },
  {
    label: 'Cameroon',
    value: 'cm',
  },
  {
    label: 'Canada',
    value: 'ca',
  },
  {
    label: 'Cape Verde',
    value: 'cv',
  },
  {
    label: 'Cayman Islands',
    value: 'ky',
  },
  {
    label: 'Central African Republic',
    value: 'cf',
  },
  {
    label: 'Chile',
    value: 'cl',
  },
  {
    label: 'China',
    value: 'cn',
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'cc',
  },
  {
    label: 'Colombia',
    value: 'co',
  },
  {
    label: 'Costa Rica',
    value: 'cr',
  },
  {
    label: "Côte d'Ivoire",
    value: 'ci',
  },
  {
    label: 'Croatia',
    value: 'hr',
  },
  {
    label: 'Curaçao',
    value: 'cw',
  },
  {
    label: 'Cyprus',
    value: 'cy',
  },
  {
    label: 'Czech Republic',
    value: 'cz',
  },
  {
    label: 'Denmark',
    value: 'dk',
  },
  {
    label: 'Djibouti',
    value: 'dj',
  },
  {
    label: 'Dominica',
    value: 'dm',
  },
  {
    label: 'Dominican Republic',
    value: 'do',
  },
  {
    label: 'Ecuador',
    value: 'ec',
  },
  {
    label: 'Egypt',
    value: 'eg',
  },
  {
    label: 'El Salvador',
    value: 'sv',
  },
  {
    label: 'Equatorial Guinea',
    value: 'gq',
  },
  {
    label: 'Estonia',
    value: 'ee',
  },
  {
    label: 'Ethiopia',
    value: 'et',
  },
  {
    label: 'Faroe Islands',
    value: 'fo',
  },
  {
    label: 'Fiji',
    value: 'fj',
  },
  {
    label: 'Finland',
    value: 'fi',
  },
  {
    label: 'France',
    value: 'fr',
  },
  {
    label: 'French Guiana',
    value: 'gf',
  },
  {
    label: 'French Polynesia',
    value: 'pf',
  },
  {
    label: 'Gambia',
    value: 'gm',
  },
  {
    label: 'Georgia',
    value: 'ge',
  },
  {
    label: 'Germany',
    value: 'de',
  },
  {
    label: 'Ghana',
    value: 'gh',
  },
  {
    label: 'Gibraltar',
    value: 'gi',
  },
  {
    label: 'Greece',
    value: 'gr',
  },
  {
    label: 'Greenland',
    value: 'gl',
  },
  {
    label: 'Grenada',
    value: 'gd',
  },
  {
    label: 'Guadeloupe',
    value: 'gp',
  },
  {
    label: 'Guam',
    value: 'gu',
  },
  {
    label: 'Guatemala',
    value: 'gt',
  },
  {
    label: 'Guernsey',
    value: 'gg',
  },
  {
    label: 'Guinea',
    value: 'gn',
  },
  {
    label: 'Guyana',
    value: 'gy',
  },
  {
    label: 'Haiti',
    value: 'ht',
  },
  {
    label: 'Honduras',
    value: 'hn',
  },
  {
    label: 'Hong Kong',
    value: 'hk',
  },
  {
    label: 'Hungary',
    value: 'hu',
  },
  {
    label: 'Iceland',
    value: 'is',
  },
  {
    label: 'India',
    value: 'in',
  },
  {
    label: 'Indonesia',
    value: 'id',
  },
  {
    label: 'Iran',
    value: 'ir',
  },
  {
    label: 'Iraq',
    value: 'iq',
  },
  {
    label: 'Ireland',
    value: 'ie',
  },
  {
    label: 'Isle of Man',
    value: 'im',
  },
  {
    label: 'Israel',
    value: 'il',
  },
  {
    label: 'Italy',
    value: 'it',
  },
  {
    label: 'Jamaica',
    value: 'jm',
  },
  {
    label: 'Japan',
    value: 'jp',
  },
  {
    label: 'Jersey',
    value: 'je',
  },
  {
    label: 'Jordan',
    value: 'jo',
  },
  {
    label: 'Kazakhstan',
    value: 'kz',
  },
  {
    label: 'Kenya',
    value: 'ke',
  },
  {
    label: 'Korea - South',
    value: 'kr',
  },
  {
    label: 'Kuwait',
    value: 'kw',
  },
  {
    label: 'Kyrgyzstan',
    value: 'kg',
  },
  {
    label: 'Laos',
    value: 'la',
  },
  {
    label: 'Latvia',
    value: 'lv',
  },
  {
    label: 'Lebanon',
    value: 'lb',
  },
  {
    label: 'Liberia',
    value: 'lr',
  },
  {
    label: 'Libya',
    value: 'ly',
  },
  {
    label: 'Liechtenstein',
    value: 'li',
  },
  {
    label: 'Lithuania',
    value: 'lt',
  },
  {
    label: 'Luxembourg',
    value: 'lu',
  },
  {
    label: 'Macao',
    value: 'mo',
  },
  {
    label: 'Macedonia',
    value: 'mk',
  },
  {
    label: 'Madagascar',
    value: 'mg',
  },
  {
    label: 'Malawi',
    value: 'mw',
  },
  {
    label: 'Malaysia',
    value: 'my',
  },
  {
    label: 'Maldives',
    value: 'mv',
  },
  {
    label: 'Mali',
    value: 'ml',
  },
  {
    label: 'Malta',
    value: 'mt',
  },
  {
    label: 'Marshall Islands',
    value: 'mh',
  },
  {
    label: 'Martinique',
    value: 'mq',
  },
  {
    label: 'Mauritius',
    value: 'mu',
  },
  {
    label: 'Mexico',
    value: 'mx',
  },
  {
    label: 'Moldova',
    value: 'md',
  },
  {
    label: 'Monaco',
    value: 'mc',
  },
  {
    label: 'Mongolia',
    value: 'mn',
  },
  {
    label: 'Montenegro',
    value: 'me',
  },
  {
    label: 'Montserrat',
    value: 'ms',
  },
  {
    label: 'Morocco',
    value: 'ma',
  },
  {
    label: 'Mozambique',
    value: 'mz',
  },
  {
    label: 'Myanmar',
    value: 'mm',
  },
  {
    label: 'Nauru',
    value: 'nr',
  },
  {
    label: 'Nepal',
    value: 'np',
  },
  {
    label: 'Netherlands',
    value: 'nl',
  },
  {
    label: 'New Caledonia',
    value: 'nc',
  },
  {
    label: 'New Zealand',
    value: 'nz',
  },
  {
    label: 'Nicaragua',
    value: 'ni',
  },
  {
    label: 'Niger',
    value: 'ne',
  },
  {
    label: 'Nigeria',
    value: 'ng',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'mp',
  },
  {
    label: 'Norway',
    value: 'no',
  },
  {
    label: 'Oman',
    value: 'om',
  },
  {
    label: 'Pakistan',
    value: 'pk',
  },
  {
    label: 'Palestinian Territory',
    value: 'ps',
  },
  {
    label: 'Panama',
    value: 'pa',
  },
  {
    label: 'Paraguay',
    value: 'py',
  },
  {
    label: 'Peru',
    value: 'pe',
  },
  {
    label: 'Philippines',
    value: 'ph',
  },
  {
    label: 'Poland',
    value: 'pl',
  },
  {
    label: 'Portugal',
    value: 'pt',
  },
  {
    label: 'Puerto Rico',
    value: 'pr',
  },
  {
    label: 'Qatar',
    value: 'qa',
  },
  {
    label: 'Reunion',
    value: 're',
  },
  {
    label: 'Romania',
    value: 'ro',
  },
  {
    label: 'Russian Federation',
    value: 'ru',
  },
  {
    label: 'Rwanda',
    value: 'rw',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'kn',
  },
  {
    label: 'Saint Lucia',
    value: 'lc',
  },
  {
    label: 'Saint Martin',
    value: 'mf',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'vc',
  },
  {
    label: 'Samoa',
    value: 'ws',
  },
  {
    label: 'Saudi Arabia',
    value: 'sa',
  },
  {
    label: 'Senegal',
    value: 'sn',
  },
  {
    label: 'Serbia',
    value: 'rs',
  },
  {
    label: 'Seychelles',
    value: 'sc',
  },
  {
    label: 'Sierra Leone',
    value: 'sl',
  },
  {
    label: 'Singapore',
    value: 'sg',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    value: 'sx',
  },
  {
    label: 'Slovakia',
    value: 'sk',
  },
  {
    label: 'Slovenia',
    value: 'si',
  },
  {
    label: 'Somalia',
    value: 'so',
  },
  {
    label: 'South Africa',
    value: 'za',
  },
  {
    label: 'Spain',
    value: 'es',
  },
  {
    label: 'Sri Lanka',
    value: 'lk',
  },
  {
    label: 'Sudan',
    value: 'sd',
  },
  {
    label: 'Suriname',
    value: 'sr',
  },
  {
    label: 'Swaziland',
    value: 'sz',
  },
  {
    label: 'Sweden',
    value: 'se',
  },
  {
    label: 'Switzerland',
    value: 'ch',
  },
  {
    label: 'Syria',
    value: 'sy',
  },
  {
    label: 'Taiwan',
    value: 'tw',
  },
  {
    label: 'Tajikistan',
    value: 'tj',
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'tz',
  },
  {
    label: 'Thailand',
    value: 'th',
  },
  {
    label: 'Timor-Leste',
    value: 'tl',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'tt',
  },
  {
    label: 'Tunisia',
    value: 'tn',
  },
  {
    label: 'Turkey',
    value: 'tr',
  },
  {
    label: 'Turkmenistan',
    value: 'tm',
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'tc',
  },
  {
    label: 'Uganda',
    value: 'ug',
  },
  {
    label: 'Ukraine',
    value: 'ua',
  },
  {
    label: 'United Arab Emirates',
    value: 'ae',
  },
  {
    label: 'United Kingdom',
    value: 'uk',
  },
  {
    label: 'United States',
    value: 'us',
  },
  {
    label: 'Uruguay',
    value: 'uy',
  },
  {
    label: 'Uzbekistan',
    value: 'uz',
  },
  {
    label: 'Vanuatu',
    value: 'vu',
  },
  {
    label: 'Venezuela, Bolivarian Republic of',
    value: 've',
  },
  {
    label: 'Vietnam',
    value: 'vn',
  },
  {
    label: 'Virgin Islands, British',
    value: 'vg',
  },
  {
    label: 'Virgin Islands, U.S.',
    value: 'vi',
  },
  {
    label: 'Yemen',
    value: 'ye',
  },
  {
    label: 'Zambia',
    value: 'zm',
  },
  {
    label: 'Zimbabwe',
    value: 'zw',
  },
];

const timezoneOption = [
  {
    label: '(GMT -12:00) Eniwetok, Kwajalein',
    value: '-12:00',
    number: -12,
  },
  {
    label: '(GMT -11:00) Midway Island, Samoa',
    value: '-11:00',
    number: 11,
  },
  {
    label: '(GMT -10:00) Hawaii',
    value: '-10:00',
    number: 10,
  },
  {
    label: '(GMT -09:00) Alaska',
    value: '-09:00',
    number: -9,
  },
  {
    label: '(GMT -08:00) Pacific Time(US & Canada)',
    value: '-08:00',
    number: -8,
  },
  {
    label: '(GMT -07:00) Mountain Time(US & Canada)',
    value: '-07:00',
    number: -7,
  },
  {
    label: '(GMT -06:00) Central Time(US & Canada), Mexico City',
    value: '-06:00',
    number: -6,
  },
  {
    label: '(GMT -05:00) Eastern Time(US & Canada), Bogota, Lima',
    value: '-05:00',
    number: -5,
  },
  {
    label: '(GMT -04:00) Atalantic Time(Canada), Caracas, La Paz',
    value: '-04:00',
    number: -4,
  },
  {
    label: '(GMT -03:00) Brazil, Buenos Aires, Georgetown',
    value: '-03:00',
    number: -3,
  },
  {
    label: '(GMT -02:00) Mid-Atlantic',
    value: '-02:00',
    number: -2,
  },
  {
    label: '(GMT -01:00) Azores, Cape Verde Islands',
    value: '-01:00',
    number: -1,
  },
  {
    label: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
    value: '+00:00',
    number: 0,
  },
  {
    label: '(GMT +01:00) Brussels, Copenhagen, Madrid, Paris',
    value: '+01:00',
    number: 1,
  },
  {
    label: '(GMT +02:00) Kaliningrad, South Africa',
    value: '+02:00',
    number: 2,
  },
  {
    label: '(GMT +03:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    value: '+03:00',
    number: 3,
  },
  {
    label: '(GMT +04:00) Abu Dhabi, Muscat, Baku, Tbilisi',
    value: '+04:00',
    number: 4,
  },
  {
    label: '(GMT +05:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    value: '+05:00',
    number: 5,
  },
  {
    label: '(GMT +06:00) Almaty, Dhaka, Colombo',
    value: '+06:00',
    number: 6,
  },
  {
    label: '(GMT +07:00) Bangkok, Hanoi, Jakarta',
    value: '+07:00',
    number: 7,
  },
  {
    label: '(GMT +08:00) Beijing, Perth, Singapore, Hong Kong',
    value: '+08:00',
    number: 8,
  },
  {
    label: '(GMT +09:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    value: '+09:00',
    number: 9,
  },
  {
    label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
    value: '+10:00',
    number: 10,
  },
  {
    label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
    value: '+11:00',
    number: 11,
  },
  {
    label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
    value: '+12:00',
    number: 12,
  },
];

const categoryOption = [
  {
    label: 'Action',
    value: 'Action',
  },
  {
    label: 'Adventure',
    value: 'Adventure',
  },
  {
    label: 'Android Wear',
    value: 'Android Wear',
  },
  {
    label: 'App',
    value: 'App',
  },
  {
    label: 'Arcade',
    value: 'Arcade',
  },
  {
    label: 'Art & Design',
    value: 'Art & Design',
  },
  {
    label: 'Arts & Entertainment',
    value: 'Arts & Entertainment',
  },
  {
    label: 'Auto & Vehicles',
    value: 'Auto & Vehicles',
  },
  {
    label: 'Beauty',
    value: 'Beauty',
  },
  {
    label: 'Board Games/Puzzles',
    value: 'Board Games/Puzzles',
  },
  {
    label: 'Board',
    value: 'Board',
  },
  {
    label: 'Books',
    value: 'Books',
  },
  {
    label: 'Business',
    value: 'Business',
  },
  {
    label: 'Card',
    value: 'Card',
  },
  {
    label: 'Casino',
    value: 'Casino',
  },
  {
    label: 'Casual',
    value: 'Casual',
  },
  {
    label: 'Catalogs',
    value: 'Catalogs',
  },
  {
    label: 'Comics',
    value: 'Comics',
  },
  {
    label: 'Communication',
    value: 'Communication',
  },
  {
    label: 'CUISINE_ET_BOISSONS',
    value: 'CUISINE_ET_BOISSONS',
  },
  {
    label: 'Dating',
    value: 'Dating',
  },
  {
    label: 'Dice',
    value: 'Dice',
  },
  {
    label: 'Display',
    value: 'Display',
  },
  {
    label: 'Drink',
    value: 'Drink',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Educational',
    value: 'Educational',
  },
  {
    label: 'Entertainment',
    value: 'Entertainment',
  },
  {
    label: 'Events',
    value: 'Events',
  },
  {
    label: 'Family',
    value: 'Family',
  },
  {
    label: 'Finance',
    value: 'Finance',
  },
  {
    label: 'Financial',
    value: 'Financial',
  },
  {
    label: 'Fitness',
    value: 'Fitness',
  },
  {
    label: 'Food & Drink',
    value: 'Food & Drink',
  },
  {
    label: 'Game Music',
    value: 'Game Music',
  },
  {
    label: 'Game',
    value: 'Game',
  },
  {
    label: 'Health & Fitness',
    value: 'Health & Fitness',
  },
  {
    label: 'Hobbies & Interests',
    value: 'Hobbies & Interests',
  },
  {
    label: 'Home & Professional Services',
    value: 'Home & Professional Services',
  },
  {
    label: 'House & Home',
    value: 'House & Home',
  },
  {
    label: 'Lifestyle',
    value: 'Lifestyle',
  },
  {
    label: 'Media & Video',
    value: 'Media & Video',
  },
  {
    label: 'Medical',
    value: 'Medical',
  },
  {
    label: 'Music',
    value: 'Music',
  },
  {
    label: 'Navigation',
    value: 'Navigation',
  },
  {
    label: 'News',
    value: 'News',
  },
  {
    label: 'NEWS_AND_MAGAZINES',
    value: 'NEWS_AND_MAGAZINES',
  },
  {
    label: 'Others',
    value: 'Others',
  },
  {
    label: 'Parenting',
    value: 'Parenting',
  },
  {
    label: 'Personalization',
    value: 'Personalization',
  },
  {
    label: 'Photo & Video',
    value: 'Photo & Video',
  },
  {
    label: 'Photography',
    value: 'Photography',
  },
  {
    label: 'Productivity',
    value: 'Productivity',
  },
  {
    label: 'Puzzle',
    value: 'Puzzle',
  },
  {
    label: 'Racing',
    value: 'Racing',
  },
  {
    label: 'Reference',
    value: 'Reference',
  },
  {
    label: 'Role Playing',
    value: 'Role Playing',
  },
  {
    label: 'rpg',
    value: 'rpg',
  },
  {
    label: 'Shopping',
    value: 'Shopping',
  },
  {
    label: 'Simulation',
    value: 'Simulation',
  },
  {
    label: 'SmarterLink',
    value: 'SmarterLink',
  },
  {
    label: 'Social',
    value: 'Social',
  },
  {
    label: 'software',
    value: 'software',
  },
  {
    label: 'Sports',
    value: 'Sports',
  },
  {
    label: 'Strategy',
    value: 'Strategy',
  },
  {
    label: 'Tools & Utilities',
    value: 'Tools & Utilities',
  },
  {
    label: 'Tools',
    value: 'Tools',
  },
  {
    label: 'Travel & Local',
    value: 'Travel & Local',
  },
  {
    label: 'Travel',
    value: 'Travel',
  },
  {
    label: 'Trivia',
    value: 'Trivia',
  },
  {
    label: 'Unclassified',
    value: 'Unclassified',
  },
  {
    label: 'Utilities',
    value: 'Utilities',
  },
  {
    label: 'Video Players & Editors',
    value: 'Video Players & Editors',
  },
  {
    label: 'Weather',
    value: 'Weather',
  },
  {
    label: 'Word',
    value: 'Word',
  },
];

const optionData = {
  carriers,
  countryOptions,
  timezoneOption,
  categoryOption,
};

export default optionData;
