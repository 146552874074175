export const myMixin = {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: 'Today',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Yesterday',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 7 Days',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
              end.setTime(end.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This Month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
              end.setTime(end.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last Month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
              end.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    // 将所有属性置空
    setNull(obj) {
      for (let i in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, i)) {
          if (typeof obj[i] === 'object') {
            let o = obj[i];
            for (let key in o) {
              o[key] = null;
            }
          } else {
            obj[i] = null;
          }
        }
      }
    },
    // 深拷贝对象
    copy(obj) {
      var _obj = JSON.stringify(obj);
      var objClone = JSON.parse(_obj);
      return objClone;
    },
  },
};
